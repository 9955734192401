import showChatInlay from './oit';
window.addEventListener('load', () => {
    const showHiddenInlayOnPage = sessionStorage.getItem('showHiddenInlayOnPage');
    const onHelpPortal = window.location.pathname.includes('help-portal');
    const onCashier = window.location.pathname.includes('pay');
    const customLiveChatButton = document.getElementById('custom-live-chat-minimized');
    if (showHiddenInlayOnPage &&
        ((onHelpPortal && showHiddenInlayOnPage.includes('help-portal')) ||
            (onCashier && showHiddenInlayOnPage.includes('pay')))) {
        const chatStatus = localStorage.getItem('inlay-oracle-chat-embedded-chatStatus');
        if (chatStatus && customLiveChatButton && JSON.parse(chatStatus).isMinimized !== false) {
            customLiveChatButton.classList.add('active');
        }
        else {
            showChatInlay();
        }
    }
    customLiveChatButton?.addEventListener('click', () => {
        customLiveChatButton.classList.remove('active');
        showChatInlay();
    });
});
document.addEventListener('chat-hours-loaded', (e) => {
    if (e.detail.chatIsOpen) {
        const startChatButton = document.getElementById('start-chat');
        if (startChatButton !== null) {
            startChatButton.addEventListener('click', () => {
                showChatInlay();
            });
        }
    }
});
