/* eslint-disable no-undef */
// 1. Wait for the OIT API to load
const showChatInlay = function () {
    sessionStorage.setItem('showHiddenInlayOnPage', window.location.pathname);
    (window.oit && (oit.load || oit.inlayIsLoaded))
        ? checkChatInlay()
        : document.addEventListener('oit-loaded', checkChatInlay);
};
// 2. Check if the Embedded Chat Inlay is loaded
const checkChatInlay = function () {
    (oit.inlayIsLoaded('chatInlay'))
        ? fireChatInlayShowEvent()
        : loadChatInlay();
};
// 3. Load the Embedded Chat Inlay
const loadChatInlay = function () {
    oit.load('chatInlay');
    // Wait for the inlay to load before showing it
    document.addEventListener('inlay-oracle-chat-embedded-loaded', fireChatInlayShowEvent);
};
// 4. Show the Embedded Chat Inlay by firing a custom show event
const fireChatInlayShowEvent = function () {
    const showFn = function () {
        oit.fire(new oit.CustomEvent('inlay-oracle-chat-embedded-show', {
            detail: { id: 'chatInlay' }
        }));
    };
    setTimeout(showFn, 0);
};
/* eslint-enable no-undef */
export default showChatInlay;
